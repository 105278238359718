/**
 * @description: 单路流分析页面接口列表
 */

import request from './request'

//  获取流列表
export function get_stream_list (data) {
  return request({
    url: '/quality_analysis_api/stream_list',
    method: 'post',
    data
  })
}
//  qoe分析
export function qoe_analysis (data) {
  return request({
    url: '/quality_analysis_api/quality_analysis',
    method: 'post',
    data
  })
}
