export function format (target, legth, substitute) {
  const strTarget = String(target)
  return strTarget.padStart(legth, substitute)
}

// 格式化日期  YYYY-MM-DD
export function formatDate (value) {
  const date = value ? new Date(value) : new Date()
  const year = date.getFullYear()
  const month = format(date.getMonth() + 1, 2, '0')
  const day = format(date.getDate(), 2, '0')
  return `${year}-${month}-${day}`
}

// 格式化时间  YYYY-MM-DD HH:mm:SS
export function formatTime (value) {
  const date = value ? new Date(value) : new Date()
  const dt = formatDate(date)
  const hour = format(date.getHours(), 2, '0')
  const minute = format(date.getMinutes(), 2, '0')
  const second = format(date.getSeconds(), 2, '0')
  return `${dt} ${hour}:${minute}:${second}`
}

// 格式化时间  MM.DD HH
export function formatTimeToMDH (value) {
  const date = value ? new Date(value) : new Date()
  const month = format(date.getMonth() + 1, 2, '0')
  const day = format(date.getDate(), 2, '0')
  const hour = format(date.getHours(), 2, '0')
  return `${month}.${day} ${hour}`
}

export function blobDodnload (res, type) {
  console.log(res)
  try {
    if (res.data.code == undefined) {
      // console.log(res)
      const contentDisposition = res.headers['content-disposition']
      const temp = contentDisposition.split('=')
      const fileName = temp[temp.length - 1]
      let url
      switch (type) {
        case 'json':
          url = window.URL.createObjectURL(new Blob([JSON.stringify(res.data, null, 2)]))
          break
        default:
          url = window.URL.createObjectURL(new Blob([res.data]))
      }
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  } catch (e) {
    console.log(e)
  }
}
