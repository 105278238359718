<!-- QOE质量分析 -->
<template>
  <div class="wraper">
    <div class="filter">
      <div class="operate">
        <span style="font-size: 14px;">{{$t('analysis.pushTerminal')}}</span>
        <el-select
          clearable
          v-model="terminal"
          :popper-append-to-body="false"
          filterable
          allow-create
          style="margin: 0 10px;"
        >
          <el-option
            v-for="item in terminalList"
            :key="item.id"
            :value="item.name"
            :label="item.name"
          />
        </el-select>
        <el-button 
          @click="getStreamList"
          type="primary"
        >
          {{ $t('common.find') }}
        </el-button>
        <el-button
          type="primary"
          @click="forceUpdate"
        >
          {{ $t("analysis.forceUpdate") }}
        </el-button>
        <span class="lastRefresh">{{ $t("analysis.lastRefresh") + last_update_time }}</span>
      </div>
    </div>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="streamData"
        row-key="serial"
        border
        :element-loading-text="$t('common.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        @select="handleSelect"
        @select-all="handleSelectAll"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="55"
        />
        <el-table-column
          prop="serial"
          :label="$t('analysis.serial')"
        />
        <el-table-column
          prop="server_ip"
          :label="$t('analysis.serverIp')"
        />
        <el-table-column
          prop="stream_name"
          :label="$t('analysis.streamName')"
        />
        <el-table-column
          prop="pull_client_ip"
          :label="$t('analysis.receiveIp')"
        />
        <el-table-column
          prop="start_time"
          :label="$t('time.startTime')"
        >
          <template slot-scope="scope">
            {{ scope.row.start_time | formatTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="end_time"
          :label="$t('time.endTime')"
        >
          <template slot-scope="scope">
            {{ scope.row.end_time | formatTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="duration"
          :label="$t('analysis.duration')"
          width="100"
        />
        <el-table-column
          prop="re_conn"
          :label="$t('analysis.reconnectFlag')"
          width="100"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.re_conn > 0"
              style="color: #F56C6C"
            >{{ scope.row.re_conn }}</span>
            <span v-else>{{ scope.row.re_conn }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.operate')">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="qoe(scope.row)"
            >
              {{ $t("analysis.qoeBtn") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :visible.sync="qoeDialog"
      :title="$t('analysis.qoeResult')"
      width="60%"
    >
      <div v-html="qoeMsg" />
      <div class="qoeDetail">
        <div class="bold mb10">
          分析结果说明：
        </div>
        <div class="bold">
          第2行“VQOE”为视频QOE情况，其中：
        </div>
        <div class="ml20">
          Score：视频QOE分数（满分100，80分以上情况良好）
        </div>
        <div class="ml20">
          VFreeze：括号中的4个数为：视频总卡顿次数、视频每分钟平均卡顿次数 、 视频总卡顿时长、视频每分钟平均卡顿时长
        </div>
        <div class="ml20">
          VBuffer：括号中的2个数为：视频收流端平均缓冲帧数、 除去采播之外的视频平均端到端延时
        </div>
        <div class="bold">
          第3行“VFreeze Distribution”，为不同卡顿时长区间的卡顿次数分布；
        </div>
        <div class="bold">
          第4行“AQOE”行为音频QOE情况，其中：
        </div>
        <div class="ml20">
          Score：音频QOE分数（满分100，80分以上情况良好）
        </div>
        <div class="ml20">
          AFreeze：括号中的4个数为：音频总卡顿次数、音频每分钟平均卡顿次数 、 音频总卡顿时长、音频每分钟平均卡顿时长
        </div>
        <div class="ml20">
          ADelay：括号中的2个数为：音频收流端平均缓冲时长、 除去采播之外的音频平均端到端延时
        </div>
        <div class="bold">
          第5行“Bitrate”为设定的码率信息，分别为：平均码率/最低码率/最高码率
        </div>
        <div class="bold">
          第6行“Publish Net”为推流端网络情况，其中：
        </div>
        <div class="ml20">
          RTT：总平均物理RTT、最小物理RTT、最大物理RTT、最近一个周期统计的RTT
        </div>
        <div class="ml20">
          Retrans：总重传率、最后一个统计周期的重传率
        </div>
        <div class="bold">
          第7行“Receive Net”为收流端网络情况，其中：
        </div>
        <div class="ml20">
          RTT：总平均物理RTT、最小物理RTT、最大物理RTT、最近一个周期统计的RTT
        </div>
        <div class="ml20">
          RTT_FULL：推流端+收流端的总平均物理RTT、最近一个周期统计的推流端+收流端的物理RTT
        </div>
        <div class="ml20">
          Retrans：总重传率
        </div>
      </div>
      <span slot="footer">
        <el-button
          type="primary"
          @click="qoeDialog=false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 错误服务器列表 -->
    <el-dialog
      :visible.sync="errServerListDialog"
      :title="$t('analysis.error_server_list')"
      width="60%"
    >
      <el-table
        :data="errServerList"
        border
      >
        <el-table-column
          label="IP"
          prop="ip"
        />
        <el-table-column
          :label="$t('analysis.errMsg')"
          prop="msg"
        />
      </el-table>
      <span slot="footer">
        <el-button
          type="primary"
          @click="errServerListDialog=false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get_stream_list, qoe_analysis } from '@/api/analysis.js'
import { formatTime as _formatTime } from '@/common/util.js'
import { get_terminals_by_type } from '@/api/terminal'
export default {
  filters: {
    formatTime (value) {
      if (!value) {
        return ''
      }
      return _formatTime(value)
    }
  },
  data () {
    return {
      streamData: [],
      last_update_time: '',
      currentPage: 1,
      pageSize: 10,
      total: null,
      chooseRows: [],
      loading: true,
      qoeDialog: false,
      qoeMsg: '',
      errServerList: [],
      errServerListDialog: false,
      terminalList: [],
      terminal:''
    }
  },
  created () {
    this.pageSize = Number(localStorage.qoePageSize) || 10
    this.init()
  },
  methods: {
    // 获取所有的推流终端
    async getPushTeminals () {
      // 0 推流， 1 收流
      const res = await get_terminals_by_type({ terminal_type: 0 })
      if (res.data.code === 0) {
        this.terminalList = res.data.data
      }
    },
    async qoe (row) {
      const loading = this.$globalLoading(this.$t('analysis.qoeAnalysis'))
      console.log(row)
      const params = {
        server_ip: row.server_ip,
        serial: row.serial
      }
      const res = await qoe_analysis(params)
      if (res.data.code === 0) {
        this.qoeDialog = true
        this.qoeMsg = res.data.data
      }
      loading.close()
    },
    async getStreamList (force = 0) {
      const params = {
        force_load: force,
        page_num: this.currentPage,
        page_size: this.pageSize,
        query_condition: {
          stream_name: {
            type: "str_like",
            value: this.terminal
          }
        }
      }
      const res = await get_stream_list(params)
      if (res.data.code === 0) {
        this.streamData = res.data.data.stream_list
        this.errServerList = res.data.data.error_server_list
        this.total = res.data.data.total
        this.last_update_time = res.data.data.last_update_time
        if (this.errServerList.length !== 0) {
          this.errServerListDialog = true
        }
      }
      this.loading = false
    },
    forceUpdate () {
      this.loading = true
      this.getStreamList(1)
    },
    // 选中表单数据，将SAID保存到this.chooseRows
    handleSelect (selection) {
      this.chooseRows = selection.map((item) => {
        return item.SAID
      })
    },
    // 全选
    handleSelectAll (selection) {
      this.chooseRows = selection.map((item) => {
        return item.SAID
      })
    },
    // 修改pageSize
    handleSizeChange (size) {
      this.pageSize = size
      localStorage.qoePageSize = size
      this.getStreamList()
    },
    // 修改当前页
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.getStreamList()
    },
    init () {
      this.getPushTeminals()
      this.getStreamList()
    }
  }
}
</script>

<style lang='scss' scoped>
.wraper {
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
  .filter {
    .operate {
      margin-top: 20px;
    }
  }
  .table {
    margin-top: 20px;
  }
  .page {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
}
.qoeDetail {
  margin-top: 20px;
  line-height: 20px;
}
.bold {
  font-weight: bold;
}
.ml20 {
  margin-left: 20px;
}
.lastRefresh {
  color: #909399;
  font-size: 12px;
  margin-left: 10px;
}
</style>
